import styled from 'styled-components'
import { Link } from 'gatsby'
import { spacing } from 'src/common/styles'

export const ButtonRow = styled.div`
  /* display: flex; */
  /* justify-content: space-between; */
  text-align: right;
  margin-top: 16px;
`

export const StoryBlank = styled.span`
  color: ${(p) => p.theme.palette.primary.light};
`
export const BottomLinkWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding: ${spacing(4)} 0;
  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${spacing(2)} 0;
  }
`
export const UppercaseLink = styled(Link)`
  text-transform: uppercase;
  color: ${(p) => p.theme.palette.primary.main};
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`
