type Story = {
  title: string
  slug: string
  text: string
}

const escobar = `You've [[verb (past tense)]] Escobar on the field, now taste him in your [[part of the body]] with Esco-Bars™! The [[adjective]] new candy that's [[verb ending in -ing]] the nation. It’s  [[adjective]] on the outside, and [[adjective]] on the inside with a [[adjective]] [[noun]] center. And mom’s, Esco-Bars™ are [[number]] percent [[noun]]-free, making them the perfect [[time of day]] snack.`

const dayOut = `“What shall I do today?”, [[verb (past tense)]] Mr. Robinson, peering through his [[noun]] on a particularly [[adjective]] morning. “I know!”, Miles exclaimed, “I shall go to [[noun]]!” And so he did. Upon stepping out into the early morning [[noun]], Mils felt [[adverb]] [[adjective]] about his trip. On his way, Barco noticed a flock of [[animal (plural)]] fly overhead. “[[exclamation]] what a [[adjective]] sight! If only I could [[verb]] like that. But alas, Barco could only [[verb]], and so he continued on his way.

As he arrived at his destination, he noticed fellow Atlanta United star [[ATLUTD player]] [[verb ending in -ing]] the [[plural noun]].

“[[greeting]], Mr. Robinson!”, his teammate yelled over with a [[adjective]] wave.”

“[[greeting]] yourself!”, replied Mr. Robinson.

All in all, Miles had a rather [[adjective]] day out, and when he returned to his [[noun]] later that [[unit of time]], he thought to himself, “[[exclamation]]!, I’m all [[adverb]] [[adjective]].” And so he was.`

const josefKnee = `Josef Martínez has been receiving [[adjective]] care for his knee injury at the [[adjective]] [[noun]] clinic. His physical therapist, Dr. [[proper noun]], is a [[place name]] renowned knee rehabilitator from [[noun]] university, and is making sure Josef practices exercises like [[verb]] [[plural noun]], and [[adjective]] [[verb ending in -ing]] regularly. Josef’s doctor says it’s imperative that Josef moves at his own [[noun]], and doesn’t [[verb]] himself too [[adverb]].`

const bradCooking = `[[salutation]] everyone. Today, we’ve got Brad Guzan here on the “Goalies Cooking [[plural noun]] Show” to teach us how to make his [[place name]] famous dish, ‘Guz [[adjective]] [[noun]]’. It looks like we’ve got some [[plural noun]] here, is that right, Brad? That’s right, [[first name]], we’re going to be [[verb ending in ing]] these in this [[adjective]] [[adjective]] sauce I prepared earlier. That smells [[adjective]] Brad! I’m getting [[adjective]] already. What’s next? So now that these guys are nice and [[adjective]], we’re going to [[verb]] them for [[number]] minutes at [[number]] degrees… and they’re done! You know it’s time to take them out of the [[noun]] when they start smelling [[adjective]]. Now all that’s left is to [[verb]] some [[plural noun]] on these babies, and [[exclamation]]!`

const stories: Story[] = [
  {
    title: "Escobar's Esco-Bars™",
    slug: 'esco-bar',
    text: escobar,
  },
  {
    title: "Mr. Robinsons's Day Out",
    slug: 'day-out',
    text: dayOut,
  },
  {
    title: "Josef's Knee Rehab",
    slug: 'josef-knee',
    text: josefKnee,
  },
  {
    title: "Let's Get Cooking With Brad Guzan",
    slug: 'brad-cooking',
    text: bradCooking,
  },
]

export default stories
