import React from 'react'
import { BottomLinkWrapper, UppercaseLink } from './styles'

const BackBottomLink = () => (
  <BottomLinkWrapper>
    <UppercaseLink to="/libs">back</UppercaseLink>
  </BottomLinkWrapper>
)

export default BackBottomLink
