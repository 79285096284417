import { Blank } from './types'
import React from 'react'
import styled from 'styled-components'

type StoryProps = {
  blanks: Blank[]
  text: string
  re: RegExp
}
const Story = React.forwardRef<HTMLDivElement, StoryProps>(
  ({ blanks, text, re }, ref) => {
    const splitText = text.split(re)

    const everything = splitText.flatMap((text, i) => {
      const paragraphSplits = text.split(/\n+/m)
      const textWithPAsNeeded =
        paragraphSplits.length > 1
          ? [paragraphSplits[0], <p key={i} />, paragraphSplits[1]]
          : paragraphSplits

      return [
        textWithPAsNeeded,
        i < splitText.length - 1 ? (
          <StoryBlank key={`${blanks[i].text}-${i}`}>
            {blanks[i].text}
          </StoryBlank>
        ) : null,
      ].flat()
    })

    return <Container ref={ref}>{everything}</Container>
  }
)

Story.displayName = 'Story'
export default Story

const Container = styled.div`
  font-family: 'Roboto';
  color: rgba(255, 255, 255, 0.9);
  font-weight: normal;
  font-size: 1.2rem;
  user-select: text;
`
const StoryBlank = styled.span`
  color: ${(p) => p.theme.palette.primary.light};
`
