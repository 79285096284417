import { PageProps } from 'gatsby'
import React, { useEffect, useContext } from 'react'
import { motion } from 'framer-motion'
import { Router, RouteComponentProps } from '@reach/router'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import SEO from 'src/common/seo'
import JosefLibs from 'src/features/josef-libs'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import stories from 'src/features/josef-libs/stories'
import { LayoutContext } from 'src/features/layout'
import { rounded, spacing } from 'src/common/styles'
import { Card } from 'src/common/styles'
// import { Particles } from '@blackbox-vision/react-particles'
import Particles from 'react-tsparticles'
import {
  UppercaseLink,
  BottomLinkWrapper,
} from 'src/features/josef-libs/styles'
import Submit from 'src/features/josef-libs/submit'

// import useTheme from 'src/common/hooks/use-theme'

const Libs = ({ location }: PageProps) => {
  const props = useMotionNavOffsetStyle({}, { dodge: 'none' })
  // const theme = useTheme()
  const { setLocation } = useContext(LayoutContext)

  useEffect(() => {
    setLocation(location)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ParticleContainer>
        <Particles
          height="100vh"
          params={{
            background: {},
            detectRetina: true,
            fpsLimit: 60,
            infection: {
              cure: false,
              delay: 0,
              enable: false,
              infections: 0,
              stages: [],
            },
            interactivity: {
              //@ts-ignore
              detectsOn: 'canvas',
              events: {
                onClick: {
                  enable: false,
                  mode: 'repulse',
                },
                onDiv: {
                  elementId: '',
                  enable: false,
                  mode: [],
                },
                onHover: {
                  enable: false,
                  // mode: 'repulse',
                  parallax: {
                    enable: true,
                    force: 60,
                    smooth: 10,
                  },
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 400,
                  duration: 2,
                  opacity: 0.8,
                  size: 40,
                },
                connect: {
                  distance: 200,
                  links: {
                    opacity: 0.5,
                  },
                  radius: 60,
                },
                grab: {
                  distance: 400,
                  links: {
                    opacity: 1,
                  },
                },
                push: {
                  quantity: 4,
                },
                remove: {
                  quantity: 2,
                },
                repulse: {
                  distance: 50,
                  duration: 0.4,
                  speed: 1,
                },
                slow: {
                  factor: 3,
                  radius: 200,
                },
              },
            },
            particles: {
              collisions: {
                enable: true,
                //@ts-ignore
                mode: 'bounce',
              },
              color: {
                // value: theme.palette.secondary.light,
                value: '#ffffff',
                animation: {
                  enable: false,
                  speed: 1,
                  sync: true,
                },
              },
              links: {
                enable: false,
              },
              move: {
                attract: {
                  enable: false,
                  rotate: {
                    x: 600,
                    y: 1200,
                  },
                },
                //@ts-ignore
                direction: 'none',
                enable: true,
                noise: {
                  delay: {
                    random: {
                      enable: false,
                      minimumValue: 0,
                    },
                    value: 0,
                  },
                  //@ts-ignore
                  enable: false,
                  //@ts-ignore
                  factor: {
                    horizontal: {
                      value: 50,
                      offset: 0,
                    },
                    vertical: {
                      value: 10,
                      offset: 40000,
                    },
                  },
                },
                //@ts-ignore
                outMode: 'bounce',
                random: true,
                speed: 2,
                straight: false,
                trail: {
                  enable: false,
                  length: 10,
                  fillColor: {
                    value: '#000000',
                  },
                },
                vibrate: true,
                warp: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                  factor: 1000,
                },
                limit: 0,
                value: 40,
              },
              opacity: {
                animation: {
                  enable: true,
                  minimumValue: 0.1,
                  speed: 0.3,
                  sync: false,
                },
                //@ts-ignore
                random: {
                  enable: false,
                  minimumValue: 1,
                },
                value: 0.5,
              },
              rotate: {
                animation: {
                  enable: true,
                  speed: 3,
                  sync: false,
                },
                //@ts-ignore
                direction: 'random',
                random: true,
                value: 0,
              },
              shadow: {
                blur: 0,
                color: {
                  value: '#000000',
                },
                enable: false,
                offset: {
                  x: 0,
                  y: 0,
                },
              },
              shape: {
                options: {
                  character: {
                    fill: true,
                    close: true,
                    //@ts-ignore
                    font: 'Roboto Mono',
                    style: '',
                    value: ['😠'],
                    weight: '400',
                  },
                },
                type: 'char',
              },
              size: {
                animation: {
                  //@ts-ignore
                  destroy: 'none',
                  enable: false,
                  minimumValue: 3,
                  speed: 5,
                  //@ts-ignore
                  startValue: 'max',
                  sync: false,
                },
                //@ts-ignore
                random: {
                  enable: true,
                  minimumValue: 3,
                },
                value: 12,
              },
              stroke: {
                color: {
                  value: '#ffffff',
                },
                width: 1,
                opacity: 1,
              },
              twinkle: {
                lines: {
                  enable: false,
                  frequency: 0.05,
                  opacity: 1,
                },
                particles: {
                  enable: false,
                  frequency: 0.05,
                  opacity: 1,
                },
              },
            },
            pauseOnBlur: true,
          }}
        />
      </ParticleContainer>
      <PageTitle {...props}>
        <Link to="/libs">
          <span role="img" aria-label="angry josef face">
            😠
          </span>{' '}
          Libs
        </Link>
      </PageTitle>
      <Router basepath="/libs">
        {stories.map((story) => (
          <JosefLibs
            path={`/${story.slug}`}
            title={story.title}
            text={story.text}
            key={story.slug}
          />
        ))}
        <Submit path="/submit" />
        <PageContent path="/" />
      </Router>
    </>
  )
}

export default Libs

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PageContent = (__: RouteComponentProps) => {
  const props = useMotionNavOffsetStyle()
  return (
    <>
      <SEO title="😠 Libs" />
      <Container {...props}>
        <NavWrapper>
          <Card>
            <List>
              {stories.map((story) => (
                <NavItem
                  onClick={() => navigate(`/libs/${story.slug}`)}
                  button
                  key={story.slug}
                >
                  <NavItemText>{story.title}</NavItemText>
                </NavItem>
              ))}
            </List>
          </Card>
        </NavWrapper>
        <BottomLinkWrapper>
          <UppercaseLink to="/libs/submit">submit your own</UppercaseLink>
        </BottomLinkWrapper>
      </Container>
    </>
  )
}

const Container = styled(motion.div)``
const ParticleContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  overflow: hidden;
`
const PageTitle = styled(motion.h1)`
  font-size: 2rem;
  text-align: center;
  padding: ${spacing(1)} 0;
  margin: 0;
  a {
    color: ${(p) => p.theme.palette.secondary.light};
    text-decoration: none;
  }
`
const NavWrapper = styled.div`
  /* text-align: center; */
  /* flex-basis: 100%; */
  display: flex;
  justify-content: center;
`

const NavItem = styled(ListItem)`
  ${rounded}
`
const NavItemText = styled(ListItemText)`
  text-align: center;
`
