import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import { Card } from 'src/common/styles'
import Link from '@material-ui/core/Link'
import BackBottomLink from './back-bottom-link'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Submit = (__: RouteComponentProps) => {
  const props = useMotionNavOffsetStyle()

  return (
    <Container {...props}>
      <SubmitCard>
        <h2>Submit your own Josef Libs</h2>
        <p>
          Interested in having your own Josef libs on the site? Write it out
          using double brackets [[like these]] around each part of
          speech/prompt, and use two returns/line-breaks for each paragraph
          break. Then{' '}
          <Link href={`mailto:${addr.join('')}`} target="_blank">
            Send an email
          </Link>{' '}
          and I'll probably add it to the site.
        </p>
        <p>For example:</p>
        <Example>
          <p>This is a [[adverb]] stupid [[noun]].</p>
          <p>
            At [[time of day]], you should really be doing [[verb ending in
            -ing]] more [[adjective]] than this.
          </p>
        </Example>
        <p>
          So if you want to send a Josef lib, email it to{' '}
          <Link href={`mailto:${addr.join('')}`} target="_blank">
            {addr.join('')}
          </Link>
          .
        </p>
      </SubmitCard>
      <BackBottomLink />
    </Container>
  )
}

export default Submit

const Container = styled(motion.div)`
  display: grid;
  justify-items: center;
`
const SubmitCard = styled(Card)`
  max-width: ${(p) => p.theme.breakpoints.values.md}px;
`
const Example = styled.div`
  font-family: 'Roboto';
  margin-left: 32px;
  user-select: text;
`
const addr = [
  'j',
  'o',
  's',
  'e',
  'f',
  '-',
  'l',
  'i',
  'b',
  's',
  '@',
  'a',
  'p',
  'p',
  'u',
  't',
  'd',
  '.',
  'c',
  'o',
  'm',
]
