import { Blank } from './types'
import React, { useRef } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { spacing } from '../standings/styles'
import { ButtonRow } from './styles'

/*
  ! This form is intentionally uncontrolled for performance reasons.
 */

type BlanksProps = {
  blanks: Blank[]
  submit(e: React.FormEvent<HTMLFormElement>): void
  clearBlanks(): void
}
const Blanks = ({ blanks, submit, clearBlanks }: BlanksProps) => {
  return (
    <>
      <Container onSubmit={submit} id="blanks-form">
        {blanks.map((blank, i) => (
          <TextField
            label={blank.pos}
            inputProps={{ autoCapitalize: 'off' }}
            defaultValue={blank.text}
            name={`${i}`}
            variant="filled"
            key={i}
            required
          />
        ))}
      </Container>
      <ButtonRow>
        <Button onClick={clearBlanks} variant="outlined">
          clear
        </Button>
        <Button
          type="submit"
          form="blanks-form"
          variant="contained"
          color="primary"
          style={{ marginLeft: '8px' }}
        >
          submit
        </Button>
      </ButtonRow>
    </>
  )
}

export default Blanks

const Container = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  column-gap: ${spacing(2)};
  row-gap: ${spacing(1)};
  .MuiFormLabel-asterisk {
    display: none;
  }
`
