import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar'
import Alert, { Color } from '@material-ui/lab/Alert'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'

type TopSnackbarProps = {
  open: boolean
  onClose?: () => void
  severity?: Color
  children?: React.ReactNode
}
const TopSnackAlert = ({
  open,
  severity,
  onClose,
  children,
}: TopSnackbarProps) => {
  const props = useMotionNavOffsetStyle(
    { alignment: 'center' },
    { dodge: 'none' }
  )

  const handleClose = (e: any, reason?: SnackbarCloseReason) => {
    if (reason !== 'clickaway') {
      // if (onClose && reason !== 'timeout') onClose()
      onClose && onClose()
    }
  }

  return (
    <motion.div {...props}>
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={10000}
      >
        <>
          <Alert
            variant="filled"
            elevation={8}
            severity={severity}
            onClose={handleClose}
          >
            {children}
          </Alert>
        </>
      </Snackbar>
    </motion.div>
  )
}

export default TopSnackAlert
