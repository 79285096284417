import { Blank } from './types'
import { RouteComponentProps } from '@reach/router'
import React, { useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import copy from 'copy-to-clipboard'
import produce from 'immer'
import styled from 'styled-components'
import { ButtonRow, BottomLinkWrapper, UppercaseLink } from './styles'
import { Card } from 'src/common/styles'
import Button from '@material-ui/core/Button'
import TopSnackAlert from 'src/features/top-snack-alert'
import Story from './story'
import Blanks from './blanks'
import useMotionNavOffsetStyle from 'src/common/hooks/use-motion-nav-offset-style'
import BackBottomLink from './back-bottom-link'

const re = /\[\[.*?\]\]/gm

type JosefLibsProps = {
  title: string
  text: string
} & RouteComponentProps
const JosefLibs = ({ title, text }: JosefLibsProps) => {
  const textRef = useRef<HTMLDivElement>(null)
  const [remountKeyHack, setRemountKeyHack] = useState(Date.now())
  const [isTextCopied, setIsTextCopied] = useState(false)
  const posArr = Array.from(text.match(re) ?? []).map((p) =>
    p.replace(/(\[\[)|(\]\])/g, '')
  )
  const [blanks, setBlanks] = useState<Blank[]>(
    posArr.map((pos) => ({ pos, text: '' }))
  )
  const [isFinished, setIsFinished] = useState(false)
  const props = useMotionNavOffsetStyle()

  //!Clearing form by re-mounting instead of reset() because of MUI's text inputs
  //!not moving their labels back to the default empty position otherwise
  const clearBlanks = () => {
    setBlanks((blanks) =>
      produce(blanks, (blanks) => {
        blanks.forEach((blank, i) => (blanks[i].text = ''))
      })
    )
    //TODO:
    // I can't remember if this will work to remount component with empty values
    // That is, will Blanks definitely remount after the text is reset?
    setRemountKeyHack(Date.now())
  }

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const inputs = e.target as any
    console.log(inputs[0].value ?? inputs)
    setBlanks((blanks) =>
      produce(blanks, (draftBlanks) => {
        draftBlanks.forEach((blank, i) => (blank.text = inputs[i].value))
      })
    )
    setIsFinished(true)
  }

  const backToEdit = () => {
    setIsFinished(false)
  }

  const copyText = () => {
    copy(title + '\n\n' + (textRef.current?.innerText ?? ''), {
      format: 'text/plain',
      onCopy: () => setIsTextCopied(true),
    })
  }

  useEffect(() => {
    const handler = () => {
      setIsTextCopied(false)
    }
    const timeout = setTimeout(handler, 5000)
    return () => clearTimeout(timeout)
  }, [isTextCopied])

  return (
    <motion.div {...props}>
      <Container>
        <TopSnackAlert
          open={isTextCopied}
          onClose={() => setIsTextCopied(false)}
        >
          Text copied!
        </TopSnackAlert>
        <StoryCard>
          <Title>{title}</Title>
          {isFinished ? (
            <>
              <Story {...{ blanks, text, re }} ref={textRef} />
              <ButtonRow>
                <Button onClick={backToEdit} variant="outlined">
                  edit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={copyText}
                  style={{ marginLeft: '8px' }}
                >
                  copy text
                </Button>
              </ButtonRow>
            </>
          ) : (
            <>
              <Blanks
                {...{ blanks, submit, clearBlanks }}
                key={remountKeyHack}
              />
            </>
          )}
        </StoryCard>
        <BackBottomLink />
      </Container>
    </motion.div>
  )
}

export default JosefLibs

const Container = styled.div`
  max-width: ${(p) => p.theme.breakpoints.values.md}px;
  margin: auto;
`
const StoryCard = styled(Card)`
  & {
    margin-top: 0;
    margin-bottom: 0;
  }
`
const Title = styled.h2`
  letter-spacing: 0.05em;
`
